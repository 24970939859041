import React from 'react'
import { Helmet } from "react-helmet"
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import '../../styles/gospelpower.scss';

const GospelPower = () => {
  return (
    <>
        <Helmet
            htmlAttributes={{
                class: 'bd-gospelpower'
            }}>
            <title>Brolga Digital || Gospel Power Ministries Church</title>
            <link rel="stylesheet" href="https://use.typekit.net/xnh3scp.css"></link>
        </Helmet>

        {/* HERO */}
        <div className='uk-text-center uk-container uk-margin-large-top uk-margin-large-bottom '>
            <div className='bd-hero uk-position-relative uk-padding-large'>
                {/* <div> */}
                    <h1>Gospel Power Ministries Church</h1>
                    <p>sharing the power of the Gospel, by praying and evangelising
                    <br/> locally, nationally and internationally</p>
                {/* </div> */}
                <StaticImage src='../../images/gospelpower/world-hands.png' alt='' className='uk-width-1-2 uk-width-1-5@s'/>
            </div>
        </div>


        {/* NAVIGATION */}
        <div uk-navbar='' className='uk-navbar-container uk-light'>
            <div className='uk-navbar-center'>
                <ul className='uk-navbar-nav'>
                    <li><Link to='#'>Home</Link></li>
                    <li><Link to='#'>Faith</Link></li>
                    <li><Link to='#'>About</Link></li>
                    <li><Link to='#'>Contact</Link></li>
                    <li><Link to='#'>Podcast</Link></li>
                </ul>
            </div>
        </div>

        <div className='uk-container uk-container-small uk-margin-large-top uk-padding uk-padding-remove-top'>

            {/* SECTION ONE */}
            <div uk-grid='' className='uk-grid-large uk-child-width-1-2@s'>
                <div>
                    <h2>Sunday Morning Gospel Service</h2>
                    <p>Please join us for a Gospel Service at the community centre, 59 Jamieson Way  Point Cook  3030.</p>
                    <p>Sing with our choir, hear a message, and enjoy God’s presence with fellow Christians.</p>
                </div>
                <div>
                    <StaticImage src='../../images/gospelpower/pastor.jpg' width='800' height='500' className='bd-image-shadow' transformOptions={{cropFocus: 'top'}} />
                </div>
                <div>
                    <StaticImage src='../../images/gospelpower/chior.jpg' width='800' height='500' className='bd-image-shadow' />
                </div>
                <div>
                    <h2>Gospel Power Ministries Church</h2>
                    <h3>Our Vision</h3>
                    <p>is to shape your life to the image of Christ and see Church Members become more like Christ in all areas of their life.</p>

                    <h3>Our Mission</h3>
                    <p>is to share the power of the Gospel, by praying and evangelising locally, nationally and internationally</p>

                    <h3>Our Values</h3>
                    <p>are Love, Faith, Encouragement, Wisdom, and the Power of God.</p>
                </div>
            </div>
            <hr />

            {/* SECTION TWO */}
            <h2>Church Meetings</h2>
            <div className='uk-position-relative'>
                <div uk-grid='' className='uk-grid-large uk-child-width-1-2@s'>
                    <div>
                        <h3>Friday Night Prayer Meetings</h3>
                        <p>Come join us for a special time of prayer at our Church on Friday evenings from 7:00 pm to 9:00 pm.</p>

                        <h3>Choir Practice</h3>
                        <p>Our Choir and Praise and Worship team meets on Saturday evening from 7:00 pm to 9:00 pm at the community centre.</p>

                        <h3>Children’s Sunday School</h3>
                        <p>We have an excellent Sunday school program for all the children starting from 11:00 am.</p>
                    </div>
                    <div>
                        <h3>Women’s Ministries</h3>
                        <p>Run in Hoppers Crossing. Come and enjoy the fellowship.<br />
                        For more information call 0429 959726.</p>

                        <h3>Men’s Barbeque Fellowship</h3>
                        <p>Run in Hoppers Crossing. Come and enjoy the fellowship.<br />
                        For more information call 0429 959726.
                        </p>
                    </div>
                </div>
                <StaticImage src='../../images/gospelpower/fish.png' alt='' className='uk-width-1-1 uk-width-1-3@s bd-bg-image'/>
            </div>
            <hr />

            {/* SECTION THREE */}
            <div uk-grid='' className='uk-child-width-1-3@m uk-grid-large'>
                <div>
                    <StaticImage src='../../images/gospelpower/barbecue.jpg' width='800' height='500' className='bd-image-shadow' />
                </div>
                <div>
                    <StaticImage src='../../images/gospelpower/celebration.jpg' width='800' height='500' className='bd-image-shadow' />
                </div>
                <div>
                    <StaticImage src='../../images/gospelpower/congregation.jpg' width='800' height='500' className='bd-image-shadow' />
                </div>
            </div>
            <hr />

            {/* FOOTER */}
            <footer>
                <h2 className='uk-text-center'>Gospel Power Ministries Church</h2>
                <div uk-grid='' className='uk-child-width-1-1 uk-child-width-1-2@m'>
                    <div uk-grid='' className='uk-child-width-1-1 uk-child-width-1-2@s'>
                        <div className='uk-flex uk-flex-row' style={{justifyContent: 'center'}}>
                            <span uk-icon="icon: location"></span>
                            <p style={{marginTop: '0', marginLeft: '5px'}}>59 Jamieson Way<br />
                            Point Cook 3030</p>
                        </div>
                        <div className='uk-flex uk-flex-row' style={{justifyContent: 'center'}}>
                            <span uk-icon="icon: receiver"></span>
                            <p style={{marginTop: '0', marginLeft: '5px'}}>0429 959 726</p>
                        </div>
                    </div>
                    <div className='uk-flex uk-flex-row' style={{justifyContent: 'center'}}>
                        <span uk-icon="icon: mail"></span>
                        <p style={{marginTop: '0', marginLeft: '5px'}}>info@gospelpowerministrieschurch.org.au</p>
                    </div>
                </div>
            </footer>
        </div>

        {/* COPYRIGHT */}
        <div className='bd-copyright uk-text-center uk-padding-small'>
            <p className='uk-margin-remove-bottom'>&copy; <Link to='https://brolgadigital.com.au'>Brolga Digital</Link> {new Date().getFullYear()}</p>
        </div>
    </>
  )
}

export default GospelPower